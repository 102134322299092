import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { Link } from 'gatsby'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as BottomMenuItemProps } from './BottomMenuItem'
import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'
import { ModalClose } from './ModalClose'
import { Navigation } from './Navigation'
import { Syncrobox } from './Syncrobox'

export interface Props {
  address?: string
  benefitsChecklist?: string[]
  benefitsImage?: string
  benefitsTitle?: string
  bottomMenuItems?: BottomMenuItemProps[]
  country?: string
  email?: string
  facebookURL?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix: string | null
  languagesList: LanguageItemProps[]
  locality?: string
  logo?: string
  logoSticky?: string
  menuItems: MenuItemProps[]
  pageID?: string
  postalCode?: string
  phone?: string
  siteName?: string
  whatsappNumber?: string
  whatsappURL?: string
  variant?: Variant
}

export const Header = memo(function Header({
  address,
  benefitsChecklist,
  benefitsImage,
  benefitsTitle,
  bottomMenuItems,
  country,
  email,
  facebookURL,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  languagesList,
  locality,
  logo,
  logoSticky,
  menuItems,
  pageID,
  postalCode,
  phone,
  siteName,
  whatsappNumber,
  whatsappURL,
  variant = 'default',
}: Props) {
  const languages = languagesList.filter((t) => t.pageID === pageID)

  const activeLanguage = useRef(null)
  const [scroll, setScroll] = useState(false)
  const [navigationStatus, setNavigationStatus] = useState(false)
  const [othersLanguagesStatus, setOthersLanguagesStatus] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeLanguage.current && !activeLanguage.current.contains(e.target)) {
      setOthersLanguagesStatus(false)
    } else {
      // @ts-ignore
      if (othersLanguagesStatus) {
        setOthersLanguagesStatus(false)
      } else {
        setOthersLanguagesStatus(true)
      }
    }
  }

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    disableScroll.off()

    window.addEventListener('scroll', onScroll)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      window.removeEventListener('scroll', onScroll)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [othersLanguagesStatus])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head
          className={scroll || variant === 'compact' ? 'fixed' : ''}
          dial={4}
          row
          variant={variant}
        >
          <Hamburger
            className="hamburger-menu"
            dial={4}
            onClick={() => {
              if (!navigationStatus) {
                setNavigationStatus(true)
                if (window.innerWidth > 1199) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setNavigationStatus(false)
              }
            }}
            row
          >
            <HamburgerLines className="hamburger-lines">
              <HamburgerLine />
              <HamburgerLine />
              <HamburgerLine />
            </HamburgerLines>
            <HambugerLabel>
              {useVocabularyData('menu', languageCode)}
            </HambugerLabel>
          </Hamburger>
          <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
            {logoSticky ? (
              <Media lessThan="desktopSmall">
                <Logo
                  className="logo"
                  src={logoSticky}
                  alt={siteName}
                  width="131"
                  height="34"
                />
              </Media>
            ) : null}
            {logo ? (
              <Media greaterThanOrEqual="desktopSmall">
                <Logo
                  className="logo"
                  src={scroll || variant === 'compact' ? logoSticky : logo}
                  alt={siteName}
                  width="186"
                  height="140"
                />
              </Media>
            ) : null}
          </Link>
          {whatsappURL ? (
            <Whatsapp
              aria-label="Whatsapp"
              className="header-whatsapp"
              href={whatsappURL}
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.998"
                height="24"
                viewBox="0 0 23.998 24"
              >
                <path
                  d="M20333-1737a11.906,11.906,0,0,1-6.6-1.983l-4.617,1.47,1.5-4.455A11.869,11.869,0,0,1,20321-1749a12.009,12.009,0,0,1,12-12,12.013,12.013,0,0,1,12,12A12.013,12.013,0,0,1,20333-1737Zm-4.523-18.511a1.7,1.7,0,0,0-1.225.429l-.035.035a3.828,3.828,0,0,0-1.189,2.882,6.757,6.757,0,0,0,1.416,3.611l.008.01c.012.016.033.047.063.092a14.536,14.536,0,0,0,5.908,5.183,10.229,10.229,0,0,0,3.514.988,2.871,2.871,0,0,0,.689-.084,3.359,3.359,0,0,0,2.357-1.692,2.972,2.972,0,0,0,.207-1.661c-.072-.124-.234-.2-.479-.317-.025-.013-.057-.027-.084-.041l-.113-.055c-.285-.144-2.051-1.014-2.387-1.133a.873.873,0,0,0-.3-.062.668.668,0,0,0-.555.322l-.148.208a12.716,12.716,0,0,1-.791,1.032.673.673,0,0,1-.5.205.917.917,0,0,1-.34-.067c-.029-.013-.061-.027-.1-.042l-.037-.016a8.788,8.788,0,0,1-2.678-1.68,10.511,10.511,0,0,1-1.945-2.419.557.557,0,0,1,.129-.739l.008-.012c.107-.131.209-.239.318-.354l.01-.011c.061-.061.127-.133.193-.209l.045-.05.018-.021a2.033,2.033,0,0,0,.328-.481.722.722,0,0,0-.053-.651c-.064-.135-.486-1.156-.729-1.752-.1-.229-.187-.448-.266-.63l-.086-.214c-.227-.552-.4-.581-.76-.6-.057,0-.115,0-.178,0h-.01C20328.627-1755.508,20328.557-1755.51,20328.479-1755.51Z"
                  transform="translate(-20321 1760.999)"
                />
              </svg>
              {whatsappNumber}
            </Whatsapp>
          ) : null}
          <Languages className="header-langs" ref={activeLanguage}>
            <LanguageActive
              className={othersLanguagesStatus ? 'open' : undefined}
            >
              {languages
                .filter((t) => t.languagePrefix === languagePrefix)
                .map((item, index) => (
                  <LanguageItem className="active" key={index} {...item} />
                ))}
            </LanguageActive>
            <OthersLanguages
              className={othersLanguagesStatus ? 'open' : undefined}
            >
              {languagesList
                .filter((t) => t.languagePrefix !== languagePrefix)
                .map((item, index) => (
                  <LanguageItem key={index} {...item} />
                ))}
            </OthersLanguages>
          </Languages>
        </Head>
        <Modal className={navigationStatus ? 'open' : ''}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              if (window.innerWidth > 1199) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setNavigationStatus(false)
            }}
            modalStatus={navigationStatus}
          />
          <Navigation
            address={address}
            benefitsChecklist={benefitsChecklist}
            benefitsImage={benefitsImage}
            benefitsTitle={benefitsTitle}
            bottomMenuItems={bottomMenuItems}
            country={country}
            email={email}
            facebookURL={facebookURL}
            IBEID={IBEID}
            instagramURL={instagramURL}
            languageCode={languageCode}
            languagePrefix={languagePrefix || 'it'}
            locality={locality}
            menuItems={menuItems}
            modalStatus={navigationStatus}
            navigationStatus={navigationStatus}
            phone={phone}
            postalCode={postalCode}
            siteName={siteName}
            whatsappURL={whatsappURL}
          />
        </Modal>
        {IBEID && variant !== 'noibe' ? (
          <Media greaterThanOrEqual="desktopSmall">
            <Syncrobox
              className={scroll || variant === 'compact' ? 'fixed' : ''}
              IBEID={IBEID}
              languagePrefix={languagePrefix || 'IT'}
            />
          </Media>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  padding-top: 1.75rem;
  padding-bottom: 1.6875rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  .logo {
    width: auto;
    height: 2.125rem;
    top: 1.375rem;
  }
  .hamburger-menu {
    color: ${theme.colors.variants.neutralDark1};
  }
  .hamburger-lines {
    div {
      background: ${theme.colors.variants.primaryLight1};
    }
  }
  .header-whatsapp,
  .header-langs a {
    color: ${theme.colors.variants.neutralDark1};
  }

  @media (max-width: 1199px) {
    padding-top: 1.625rem;
    padding-bottom: 1.5625rem;
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  padding-top: 2.25rem;
  position: relative;
  &.fixed {
    ${HeaderFixedStyle}
  }

  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return css`
          &.fixed {
            animation: fadeInTop 0.3s;
          }
        `
      case 'noibe':
        return css`
          &.fixed {
            animation: fadeInTop 0.3s;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    ${HeaderFixedStyle}
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Hamburger = styled(FlexBox)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-right: auto;
  margin-left: 3rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(1) {
          width: 50%;
        }
        &:nth-of-type(3) {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    margin-left: 1.25rem;
  }
`

const HamburgerLines = styled.div`
  width: 2.25rem;
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  transition: 0.3s ease-in-out;
  &:nth-of-type(2) {
    margin: 8px 0;
  }
  &:nth-of-type(3) {
    width: 50%;
  }
`

const HambugerLabel = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0.1031rem;
  margin-left: 1rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Logo = styled.img`
  width: auto;
  height: 8.75rem;
  margin: auto;
  position: absolute;
  top: 2.25rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1199px) {
    top: 1.1875rem !important;
  }
`

const Whatsapp = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }

  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-right: 0.625rem;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Languages = styled.div`
  margin: 0 3rem 0 2.75rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    margin: 0 1.25rem 0 0;
  }
`

const LanguageActive = styled.div`
  cursor: pointer;
  &.open {
    svg {
      transform: scaleY(-1);
    }
  }
`

const OthersLanguages = styled.div`
  position: absolute;
  top: 1.875rem;
  left: -0.375rem;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.25s ease-in-out;
  &.open {
    transform: scaleY(1);
  }
  a {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    color: ${({ theme }) => theme.colors.variants.neutralDark3};
    padding: 0.3125rem 0.75rem;
    svg {
      display: none;
    }
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'noibe'
